/*Now the CSS*/
* {
  margin: 0;
  padding: 0;
}
.nft__area {
  margin-top: 120px;
}
ul {
  padding: 0px;
}
.tree ul {
  padding-top: 20px;
  position: relative;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #362465;
  width: 50%;
  height: 20px;
}
.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #362465;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child {
  padding-top: 0;
}

/*Remove left connector from first child and 
right connector from last child*/
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before {
  border-right: 1px solid #362465;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul li a::before {
  content: "";
  position: absolute;
  top: 34%;
  left: 50%;
  border-left: 1px solid #362465;
  width: 0;
  height: 30px;
}

.tree li a {
  /* border: 2px solid #442691; */
  padding: 10px 10px;
  text-decoration: none;
  color: #fff;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;
  width: 235px;
  /* background-color: #362465; */
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}
.tree-para{
 
  font-size: 15px;
}
.tree-para:hover{
 
  font-size: 14px;
}
/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover,
.tree li a:hover + ul li a {
  background: #a086e3;
  color: #fff;
  border: 2px solid #a086e3;
}

/*Connector styles on hover*/
.tree li a:hover + ul li::after,
.tree li a:hover + ul li::before,
.tree li a:hover + ul::before,
.tree li a:hover + ul ul::before {
  border-color: #94a0b4;
}
.userTree {
  color: #362465;
}

.userCircle {
  color: azure;
  font-size: 50px !important;
}

ul.subtree {
  display: flex;
  max-width: 900px;
  overflow: auto;
}
ul.subtree li {
  display: inline-block !important;
}
ul.subtree li a {
  width: 126px;
}

@media (max-width: 1430px) {
  .nft__area {
    margin-top: 10px;
  }
  ul.subtree {
    display: flex;
    max-width: 800px;
    overflow: auto;
  }
}
@media (min-width: 870px) {
  .treresp {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media (max-width: 850px) {
  /* .treresp {
    overflow-x: scroll !important;
  } */
  .treresp::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .treresp::-webkit-scrollbar-thumb {
    background-color: rgb(133, 129, 129);
    border-radius: 3px;
  }
  ul.subtree {
    display: flex;
    max-width: 600px;
    overflow: auto;
  }
}

@media (max-width: 600px) {

  ul.subtree {
    display: flex;
    max-width: 400px;
    overflow: auto;
  }
  /* .nft__area {
    margin-top: 80px;
  }
  .tree {
    padding: 0 !important;
  }
  ul.subtree li a {
    width: 100px;
    height: 125px;
    padding: 10px 5px !important;
  }
  ul.subtree {
    display: inline-block;
    max-width: unset;
    overflow: unset;
  }
  .tree li {
    float: left;
    position: relative;
    padding: 19px 0 0 5px;
  }
  .tree li:nth-child(n + 4)::before,
  .tree li:nth-child(n + 4)::after {
    border-top: none;
    height: 30px !important;
  }
  .tree li:nth-child(n + 3)::before,
  .tree li:nth-child(n + 3)::after {
    width: 50%;
    left: 0;
    border-left: 1px solid transparent !important;
    border-right: 1px solid #362465 !important;
  } */
}

@media (max-width: 400px) {

  ul.subtree {
    display: flex;
    max-width: 350px;
    overflow: auto;
  }

}
